import React, { useState, useRef, useEffect } from "react";
import { useAppContext } from '../../../../../Lib/UserContext'
import * as d3 from "d3";
import "./InteractivePieChart.css";

export default function HorizontalAndDonutChart(props) {
  const { chartColorArray } = useAppContext()
  const histogramRef = useRef(null);
  const [currentXaxis, setCurrentXaxis] = useState(props.activeCategory ?? { id: props.keys[0].variable, color: chartColorArray[0], text: `Concern Level for ${props.keys[0].name}` });
  

  useEffect(() => {
    if (props.data?.length > 0) {
      var data = props.reverse ? props.data?.reverse() : props.data

      let colors = {};
      for (let i = 0; i < props.keys.length; i++) {
        colors[props.keys[i].variable] = chartColorArray[i];
      }
      
      const svg = d3.select(histogramRef.current);
      svg.selectAll("svg").remove();
      svg.selectAll("table").remove();
      svg.selectAll("div").remove();
 
      let margin = { top: 10, right: 20, bottom: 90, left: 20 };
      let yaxisSpace = 80
      let horizontalWidth = 500 // 400 // 250
      let horizontalHeight = 570

      

      let donutColorIndex = props.donutColorIndex;
      
      // compute total for each obj
      data.forEach(function (d) {
        var childrenTotalValue = 0;
        d.children.forEach(function (item) {
          childrenTotalValue += Number(item.value);
        });
        return (d.total = childrenTotalValue);
      });

      let totalOverAll = d3.sum(data.map(d => d.total ?? 0))
  
      let max = Math.ceil(d3.max(data, (d) => +d.total) / 100) * 100

      if (max === 0) {
        max = 5
      }

      // Creating the tooltip
      var tooltip = d3
        .select(histogramRef.current)
        .append("div")
        .style("opacity", 0)
        .attr("class", "tooltip")
        .style("background-color", "white")
        .style("color", "black")
        .style("border", "solid")
        .style("border-width", "2px")
        .style("border-radius", "5px")
        .style("position", "fixed")
        .style("z-index", "-1")
        .style("padding", "5px");
      tooltip.append("div").attr("class", "title");
      tooltip.append("div").attr("class", "label");
      tooltip.append("div").attr("class", "percent");

      

      function hMouseOver(d) {
        // console.log('d', d)
        tooltip.style("opacity", 1);
        tooltip.style("z-index", "9999");

        let label = props.keys.filter(k => k.variable === d.data.type)[0].name
        let value = d.data.total;
        // let total = data.filter(f => f.id === d.data.parent)[0].total
        let percent = Math.round((1000 * value) / totalOverAll) / 10;

        tooltip.select(".title").text(label);
        tooltip
          .select(".label")
          .text(
            "Total: " +
            value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          );
        tooltip.select(".percent").text("Percent: " + percent + "%");

        // call the update function of pieChart with new data.
        let newData;
        data.forEach((p) => {
          if (p.id === d.data.type) {
            return newData = p.children.map(c => { return { type: c.id, total: c.value, parent: p.id }; });
          }
        });
        let keyObj = props.keys.filter(k => k.variable === d.data.type)[0]
        let updateCurrentXaxis = {
          id: keyObj.variable,
          color: colors[d.data.type],
          text: `Concern Level for ${keyObj.name}`
        };
        pC.update(newData, updateCurrentXaxis);
        setCurrentXaxis(updateCurrentXaxis);
        if (props.updateActiveCategory) {
          props.updateActiveCategory(updateCurrentXaxis);
        }
        // ,
        // tableColorArray[data.map(obj => obj.id).indexOf(d.data.type)]
      }
      
      function hMouseOut() {
        tooltip.style("opacity", 0)
          .style("z-index", "-1");
      }

      function hMouseMove(d) {
        tooltip
          .style("opacity", 1)
          .style("z-index", "9999")
          .style("top", d3.event.clientY - 110 + "px")
          .style("left", d3.event.clientX - 75 + "px");
      }



      function pieMouseover(d) {
        tooltip.style("opacity", 1);
        tooltip.style("z-index", "9999");

        let label = d.data.type;
        let value = d.data.total;
        let total = data.filter(f => f.id === d.data.parent)[0].total;

        let percent = Math.round((1000 * value) / total) / 10;
        tooltip.select(".title").text(label);
        tooltip
          .select(".label")
          .text(
            "Total: " +
            value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          );
        tooltip.select(".percent").text("Percent: " + percent + "%");
      }

      function pieMouseOut() {
        tooltip.style("opacity", 0)
          .style("z-index", "-1");
      }

      function pieMouseMove(d) {
        tooltip
          .style("opacity", 1)
          .style("z-index", "9999")
          .style("top", d3.event.clientY - 150 + "px")
          .style("left", d3.event.clientX - 150 + "px");
      }

      function wrap(text, width) {
        text.each(function () {
          var text = d3.select(this),
            words = text.text().split(/\s+/).reverse(),
            word,
            line = [],
            lineNumber = 0,
            lineHeight = 1, // ems
            y = text.attr("y"),
            dy = 0.01, // parseFloat(text.attr("dy")),
            tspan = text
              .text(null)
              .append("tspan")
              .attr("x", 0)
              .attr("y", y)
              .attr("dy", dy + "em");
          while ((word = words.pop())) {
            line.push(word);
            tspan.text(line.join(" "));
            if (tspan.node().getComputedTextLength() > horizontalWidth && line.length > 1) {
              line.pop();
              tspan.text(line.join(" "));
              line = [word];
              tspan = text
                .append("tspan")
                .attr("x", 0)
                .attr("y", y)
                .attr("dy", ++lineNumber * lineHeight + dy + "em")
                .text(word);
            }
          }
        });
      }

      // horizontal bar chart
      let barsvg = svg
        .append("svg")
        .attr('class', 'horizontalChart')
        .attr("width", horizontalWidth)
        .attr("height", horizontalHeight)
        .append("g")
        .attr("transform", `translate(${yaxisSpace}, ${margin.top})`);
      
      let x = d3
        .scaleLinear()
        .domain([0, max])
        .range([margin.left, horizontalWidth - margin.right - yaxisSpace])
      //.nice();

      let y = d3
        .scaleBand()
        .domain(d3.range(data.length))
        .range([horizontalHeight - margin.bottom, margin.top])
        .padding(0.1);

      let xAxisFormat = d3.axisBottom(x).tickSizeOuter(0);

      let xAxis = (g) =>
        g
          .attr("transform", `translate(0,${horizontalHeight - margin.bottom})`)
          .call(xAxisFormat);

      let yAxis = (g) =>
        g.attr("transform", `translate(${margin.left},0)`).call(
          d3
            .axisLeft(y)
            .tickSize(0)
            .tickFormat((i) => data[i]?.id ?? '')
        ).selectAll(".tick text")
          .call(wrap, 150);
      
      barsvg
        .append("g")
        .selectAll("rect")
        .data(data)
        .join("rect")
        .on("mouseover", (d => hMouseOver({
          data: {
            type: d.id,
            total: d.total,
          }
        })))
        .on("mouseout", hMouseOut)
        .on("mousemove", hMouseMove)
        .attr("x", (d) => x(0))
        .attr("y", (d, i) => {
          if (data.length === 1) {
            return y(i) + 90
          } else if (data.length === 2) {
            return y(i) + 30
          } else {
            return y(i)
          }
        })
        .attr("height", data.length <= 6 ? 30 : 15)
        .attr("width", (d) => x(d.total) - x(0))
        .attr("fill", (d, i) => colors[d.id]);

      barsvg.append("g").call(xAxis);

      barsvg.append("g").call(yAxis);

      // text label for the x axis
      barsvg
        .append("text")
        .attr(
          "transform",
          `translate(${(horizontalWidth / 2.4) - margin.left},${horizontalHeight - (margin.bottom/2.5)})`
        )
        .style("text-anchor", "middle")
        .style('font-size', '16px')
        .text(props.xaxis);

      // text label for the y axis
      barsvg
        .append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", -70)
        .attr("x", -(horizontalHeight / 2) + 10)
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .style('font-size', '16px')
        .text(props.yaxis);


      // function to handle pieChart.
      function pieChart(pD, xaxis) {
        var pC = {},
          pieDim = { w: 280, h: 230 };
        pieDim.r = Math.min(pieDim.w, pieDim.h) / 2;

        // create svg for pie chart.
        var piesvg = svg
          .append("svg")
          .attr("class", "pieChart")
          .style('order', '2')
          .attr("width", pieDim.w)
          .attr("height", pieDim.h)
          .append("g")
          .attr(
            "transform",
            "translate(" + pieDim.w / 2 + "," + pieDim.h / 2 + ")"
          );

        // create function to draw the arcs of the pie slices.
        var arc = d3
          .arc()
          .outerRadius(pieDim.r - 10)
          .innerRadius(40); // innerRadius creates the white space in the middle


        // create a function to compute the pie slice angles.
        var pie = d3
          .pie()
          .sort(null)
          .value(function (d) {
            if (d.total === 0) {
              return 0.05;
            } else {
              return d.total;
            }
          });

        // Draw the pie slices.
        piesvg
          .selectAll("path")
          .data(pie(pD))
          .enter()
          .append("path")
          .attr("d", arc)
          .on("mouseover", pieMouseover)
          .on("mouseout", pieMouseOut)
          .on("mousemove", pieMouseMove)
          .each(function (d) {
            this._current = d;
          })
          .style("fill", function (d) {
            return donutColorIndex.filter(f => f.name === d.data.type)[0].color;
          });
        // pie xaxis label
        let xaxisSvg = svg
          .append("div")
          .style('order', '3')
          .style("min-width", `${pieDim.w}px`)
          .style('height', '20px')
          .style('position', 'absolute')
          .style('left', `${horizontalWidth}px`)
          .style('bottom', `${horizontalHeight / 4.25}px`);
          //  .style('bottom', '-20px');
    
        xaxisSvg
          .append('div')
          .attr('class', 'xaxisDiv')
          // .style("width", '100%')
          // .style("transform", `translate(${pieDim.w}, ${(pieDim.h / 2 + 10)})`)
          // .style('margin-left', 580 - pieDim.w)
          .style("text-anchor", "middle")
          .style('display', 'flex')
          .style('flex-direction', 'row')
          .style('align-items', 'center')
          .style("justify-content", "center")
          .append("svg")
          .attr("width", "20")
          .attr("height", "20")
          .style('margin-right', 7)
          .append('rect')
          .attr('class', 'xaxisColor')
          .attr("width", "20")
          .attr("height", "20")
          .attr('rx', '4px')
          .attr('ry', '4px')
          .attr("fill", xaxis.color);

        xaxisSvg
          .select('.xaxisDiv')
          .append('span')
          .attr('class', 'xaxisText')
          .style("color", "#2a363d")
          .style('white-space', 'nowrap')
          .style('font-size', '14px')
          .text(xaxis.text);


        // create function to update the pie slices.
        pC.update = function (data, xaxis) {
          // piesvg
          //   .selectAll("path")
          //   .data(pie(data))
          //   .transition()
          //   .duration(500)
          //   .append("path")
          //   .attr("d", arc)
          //   .each(function (d) {
          //     this._current = d;
          //   })
          //   .style("fill", function (d) {
          //     return donutColorIndex.filter(f => f.name === d.data.type)[0].color;
          //   });
          piesvg
            .selectAll("path")
            .data(pie(data))
            .transition()
            .duration(500)
            .attrTween("d", arcTween)
            // .append("path")
            // .attr("d", arc)
            .each(function (d) {
              this._current = d;
            })
            .style("fill", function (d) {
              return donutColorIndex.filter(f => f.name === d.data.type)[0].color;
            });

          xaxisSvg
            .select(".xaxisColor")
            .attr('fill', xaxis.color);

          xaxisSvg
            .select(".xaxisText")
            .text(xaxis.text);
        };
        return pC;

        //Utility function to be called on mouseout a pie slice.
        // function mouseout(d) { //(NOT NEEDED ANYMORE?)
        //   // call the update function of histogram with all data.
        //   hG.update(
        //     data.map(function (v) {
        //       return [v.State, v.total];
        //     }),
        //     barColor
        //   );
        // }
        // Animating the pie-slice requiring a custom function which specifies
        // how the intermediate paths should be drawn.
        // eslint-disable-next-line no-unused-vars
        function arcTween(a) {

          var i = d3.interpolate(this._current, a);
          this._current = i(0);
          return function (t) {
            return arc(i(t));
          };
        }
        // return pC;
      }

      let pieDataToUse = data?.filter(f => f.id === currentXaxis.id)?.[0]
      
      var tP = pieDataToUse?.children?.map(c => {
        return {
          type: c.id,
          total: c.value,
          parent: pieDataToUse.id
        };
      });

        // eslint-disable-next-line no-unused-vars
      let pC = pieChart(tP, currentXaxis); // create the pie-chart
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  return ( // css inline for generating report to work
    <div id={props.id} style={{ paddingBottom: props.report ? 0 : 40 }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div
          className='histogramRef tableHoverDonutChart'
          ref={histogramRef}
          style={{ position: 'relative', display: 'flex', alignItems: 'center', overflow: 'visible' }}
        ></div>
        <div style={{ width: 'fit-content', padding: 5, borderRadius: 5, border: '4px solid white', borderColor: currentXaxis?.color }}>
          {props.donutColorIndex.map((dci, i) => {
            return (
              <div key={i} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ width: 30, height: 10, backgroundColor: dci.color, marginRight: 5, borderRadius: '4px' }} />
                <span style={{ color: '#2a363d', fontSize: '14px' }}>{dci.name}</span>
              </div>
            );
          })}
        </div>
      </div>
      {props.report && <><br></br> <br></br></>}

    </div>
  );
}