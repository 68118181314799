import React, { useState, useEffect, Fragment } from "react";
// import { useAppContext } from "../../../../Lib/UserContext";
import "./Demographics.css";
import { makeStyles } from "@material-ui/core/styles";

import SingleCard from "../../../../Components/Card";
import { SingleBarGraph } from "../../GraphComponents/SingleBarGraph";
import HorizontalBarChart from "../../GraphComponents/HorizontalBarChart";

import AgeTable from "./Tables/AgeTable";
import TableWithTotal from '../../../../Components/ReusableTables/TableWithTotal'
// import TableForYearGraphs from '../../../../Components/ReusableTables/TableForYearGraphs'


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    cardContainer: {
        // width: "calc(100% - 200px)",
        // padding: "20px 10px",
        // marginTop: "80px",
    },
    openIcon: {
        color: "#28ADE3",
    },
    countyList: {
        padding: 0,
    },
    toolbar: {
        boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
    content: {
        marginTop: "64px",
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: 0,
    },
}));

export default function Screenings(props) {
    const classes = useStyles();
    // const { chartColorArray } = useAppContext()
    const [graphData, setGraphData] = useState([]);

    useEffect(() => {
        if (props.data) {
            setGraphData(props.data)
            console.log('props.data', props.data)
        }
    }, [props.data])

    const renderAllCharts = () => {
        return (
            <div className={classes.root}>
                <div className={classes.cardContainer}>
                    <SingleCard
                        // className={classes.card}
                        width="100%"
                        id="Demographics Age"
                        title="Demographics  >  Age"
                        type="Screenings"
                        graph={
                            <SingleBarGraph
                                data={graphData.ageData ?? []}
                                xaxis="Age"
                                labelOnHover
                                xaxisVar="age"
                                yaxis="Number of Screenings"
                                type="cc"
                                smalltext={true}
                            />
                        }
                        table={<AgeTable data={graphData.ageData ?? []} />}
                        definitions={[["", "This chart displays the age of individuals at the time of screening, based on selected filters. Values of five or less do not appear in the chart or table. The Ages filters does not apply to this chart."]]}
                        addToReports={props.addToReportPage}
                    />

                    <SingleCard
                        // className={classes.card}
                        width="100%"
                        id="Demographics Gender"
                        title="Demographics  >  Gender"
                        type="Screenings"
                        graph={
                            <SingleBarGraph
                                data={graphData.genderData ?? []}
                                xaxis="Gender"
                                labelOnHover
                                xaxisVar="gender"
                                yaxis="Number of Screenings"
                                type="cc"
                                smalltext={true}
                            />
                        }
                        table={
                            <TableWithTotal
                                data={graphData.genderData ?? []}
                                headers={['Gender', 'Number of Screenings']}
                                columnKeys={['gender', 'value']}
                                totalKey='value'
                            />
                        }
                        definitions={[["", "This chart displays the gender of individuals at birth, based on selected filters. Values of five or less do not appear in the chart or table. All filters apply to this chart."]]}
                        addToReports={props.addToReportPage}
                    />


                    <SingleCard
                        // className={classes.card}
                        title="Indicators  >  Indicator Prevalence"
                        id="Indicators Indicator Prevalence"
                        graph={
                            <HorizontalBarChart
                                data={graphData.indicators ?? []}
                                value="xaxis"
                                xaxis="Number of Screenings"
                                yLabelsWidth={220}
                            />
                        }
                        table={
                            <TableWithTotal
                                noTotalRow                      
                                descending
                                data={graphData.indicators ?? []}
                                headers={['Indicators', 'Number of Screenings']}
                                columnKeys={['xaxis', 'total']}
                                totalKey='total'
                            />
                        }
                        definitions={[["", 'This chart displays the prevalence of each "Evidence of" indicator scored as a "Yes," based on selected filters. All filters apply to this chart.']]}
                        addToReports={props.addToReportPage}
                    />
                </div>
            </div>
        )
    }


    const renderSingleChart = () => {
        const charts = renderAllCharts();
        const singleCards = charts.props.children.props.children;

        const index = singleCards.findIndex((card) => card.props.id === props.chartId);
        if (index <= -1) {
            return <Fragment></Fragment>;
        }

        const elementToRender = singleCards[index];
        return <SingleCard
            {...elementToRender.props}
            title={props.chartTitle || props.title}
            description={props.chartInsight || ""}
            shouldUseNewStyles={true}
        />;
    }

    return (
        props.chartId
            ? renderSingleChart()
            : renderAllCharts()
    );
}